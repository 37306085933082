// src/lib/redux/userFavoritesBookOffers/ActionTypes.js
const ACTION_TYPES = {
    FAVORITE_BOOK_OFFERS_PENDING: 'FAVORITE_BOOK_OFFERS_PENDING',
    FAVORITE_BOOK_OFFERS_SET: 'FAVORITE_BOOK_OFFERS_SET',
    FAVORITE_BOOK_OFFERS_REMOVE: 'FAVORITE_BOOK_OFFERS_REMOVE',
    FAVORITE_BOOK_OFFERS_ERRORR: 'FAVORITE_BOOK_OFFERS_ERRORR',
    FAVORITES_BOOK_OFFERS_RESET: 'FAVORITES_BOOK_OFFERS_RESET',
    FAVORITE_BOOK_OFFERS_SUCCESS: 'FAVORITE_BOOK_OFFERS_SUCCESS',
};
export default ACTION_TYPES;

// https://reactnativeforyou.com/react-native-api-call-example-with-axios-redux-and-hooks/
