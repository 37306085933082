import React, { createContext, useContext, useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

const GeolocationContext = createContext();

export const useGeolocation = () => useContext(GeolocationContext);

const cookies = new Cookies();

export const GeolocationProvider = ({ children }) => {
    const [coordinates, setCoordinates] = useState({
        latitude: cookies.get('userLatitude') || null,
        longitude: cookies.get('userLongitude') || null
    });
    const [isGeolocation, setIsGeolocation] = useState(false);
    const intl = useIntl();
    const _geolocationError = intl.formatMessage({ id: "GeolocationError" });
    const _geolocationSuccess = intl.formatMessage({ id: "GeolocationSuccess" });

    const updateCoordinates = (newLatitude, newLongitude) => {
        setCoordinates({ latitude: newLatitude, longitude: newLongitude });
        cookies.set('userLatitude', newLatitude, { path: '/', sameSite: 'none', secure: true });
        cookies.set('userLongitude', newLongitude, { path: '/', sameSite: 'none', secure: true });
    };

    const errorGeolocationClick = (err) => {
        console.warn(`ERROR(${err.code}): ${err.message}`);
        toast.error(_geolocationError);
        setIsGeolocation(false);
    };

    const successGeolocationClick = (pos) => {
        const crd = pos.coords;                
        if (crd.latitude && crd.longitude) {
            toast.success(_geolocationSuccess);
            updateCoordinates(crd.latitude, crd.longitude);
            setIsGeolocation(false);
        }
    };

    const handleGeolocationClick = () => {
        setIsGeolocation(true);
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(successGeolocationClick, errorGeolocationClick, {
                enableHighAccuracy: true,
                timeout: 5000,
                maximumAge: 0
            });
        } else {
            console.log('Geolocation Not Available');
            toast.error(_geolocationError);
            setIsGeolocation(false);
        }
    };

    // Opcjonalnie: reaguj na zmiany cookies
    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         const newLatitude = cookies.get('userLatitude') || null;
    //         const newLongitude = cookies.get('userLongitude') || null;

    //         if (newLatitude !== coordinates.latitude || newLongitude !== coordinates.longitude) {
    //             setCoordinates({ latitude: newLatitude, longitude: newLongitude });
    //         }
    //     }, 1000); // Sprawdza co 1 sekundę

    //     return () => clearInterval(interval);
    // }, [coordinates])

    return (
        <GeolocationContext.Provider value={{ coordinates, isGeolocation, handleGeolocationClick, updateCoordinates }}>
            {children}
        </GeolocationContext.Provider>
    );
};

// PropTypes
GeolocationProvider.propTypes = {
    children: PropTypes.node,
};
