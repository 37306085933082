// src/lib/redux/locationsSlice/index.js
import { createSlice } from '@reduxjs/toolkit';

export const locationsSlice = createSlice({
    name: 'locations',
    initialState: {
        locationsId: null,
    },
    reducers: {
        setLocationsId: (state, action) => {
            state.locationsId = action.payload;
        },
        resetLocationsId: (state) => {
            state.locationsId = null;
        },
    },
});

export const { setLocationsId, resetLocationsId } = locationsSlice.actions;

export default locationsSlice.reducer;