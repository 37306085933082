// src/lib/redux/userFavoritesBookOffers/ApiReducer.js
import ACTION_TYPES from './ActionTypes';

const initialState = {
  loading: false,
  data: [], // Lista obiektów book offers
  error: '',
};

const userFavoritesBookOffersReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.FAVORITE_BOOK_OFFERS_PENDING:
            return {
                ...state,
                loading: true,
            };
        case ACTION_TYPES.FAVORITE_BOOK_OFFERS_SET:
            return {
                ...state,
                // data: [...state.data, action.payload.details],
                // data: [...state.data, action.payload], // Dodanie na koniec listy
                data: [action.payload, ...state.data], // Dodanie na początek listy
                loading: false,
            };
        case ACTION_TYPES.FAVORITE_BOOK_OFFERS_REMOVE:
            return {
                ...state,
                data: state.data.filter(item => item.id !== action.payload),
                loading: false,
              };
        case ACTION_TYPES.FAVORITE_BOOK_OFFERS_ERRORR:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        case ACTION_TYPES.FAVORITES_BOOK_OFFERS_RESET:
            return {
                ...initialState
            };
        case ACTION_TYPES.FAVORITE_BOOK_OFFERS_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        default:
            return state;
    }
};

export default userFavoritesBookOffersReducer;
