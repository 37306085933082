// src/utils/index.js
import React, { useState, useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
// import Cookies from 'universal-cookie';
// import { transliterate as tr, slugify } from 'transliteration';
import { slugify } from 'transliteration';
// import fetch from 'node-fetch';
import { FaCheck, FaTimes, FaQuestion } from 'react-icons/fa';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import { IconPaths } from '../constants';

export const changeHttpToHttps = (url) => {
    if ( url )
        return url.replace(/^http(s)?/, 'https');
    else
        url;
};

// async function convertImageToBase64(url) {
//     try {
//         const response = await fetch(url);
//         const arrayBuffer = await response.arrayBuffer();
//         const buffer = Buffer.from(arrayBuffer);
//         return buffer.toString('base64');
//     } catch (error) {
//         console.error("Error converting image to Base64:", error);
//         return null;
//     }
// }

export const convertDistance = (distance) => {
    if (distance){
        let result;
        if (parseFloat(distance) < 1) {
            // result = (distance * 1000).toFixed(2) + 'm';
            result = parseInt((distance * 1000).toFixed(2)) + 'm';
        } else {
        result = distance.toFixed(2) + 'km';
        }
        return result;
    }else{
        return null;
    }
}

export const createSitemapUrl = (data, lang) => {
    // format/:publisher?/:category?/:subcategory?/title/:authors/:isbn?:id
    const subcategory = null;

    const languagePaths = {
        en: { path: '/book/', categoryKey: 'name_en' },
        de: { path: '/buch/', categoryKey: 'name_de' },
        pl: { path: '/ksiazka/', categoryKey: 'name' },
        uk: { path: '/knyha/', categoryKey: 'name_uk' }
    };

    const buildPath = (data, lang) => {
        const languagePath = languagePaths[lang] || {};
        let path = languagePath.path || '/';
        
        if (data.publisher) path += `${slugify(data.publisher).replace(/-+/g, '-')}/`;
        if (data.category) path += `${slugify(data.category).replace(/-+/g, '-')}/`;
        if (subcategory) path += `${slugify(subcategory)}/`;
        if (data.title) path += `${slugify(data.title).replace(/-+/g, '-')}/`;
        if (data.authors) path += `${slugify(data.authors).replace(/-+/g, '-')}/`;
        if (data.isbn) path += `${slugify(data.isbn).replace(/-+/g, '-')}/`;

        if (data.id) path += `${data.id}`;
    
        return path;
    };

    return buildPath(data, lang);
}

export const changeUrlWithoutReloading = (newUrl) => {
    window.history.pushState({ path: newUrl.toString() }, '', newUrl.toString());
};

export const detectCapitalLetters = (str, capitalLetters) => {
    let count = 0;
    for (let i = 0; i < str.length; i++) {
        if (str[i] === str[i].toUpperCase()) {
            count++;
        }
    }
    return count >= capitalLetters;
}


export const extractImageSourceFromUrl = (url) => {
    // Lista znanych domen i ich nazw źródeł
    const sources = {
      'images-eu.ssl-images-amazon.com': 'Amazon',
      'elibri.com': 'Elibri',
      // Dodaj więcej domen w razie potrzeby
    };
  
    // Spróbuj znaleźć nazwę źródła na podstawie domeny
    for (const domain in sources) {
      if (url.includes(domain)) {
        return sources[domain];
      }
    }
  
    // Zwróć 'Inne' jeśli żadna znana domena nie została znaleziona
    return 'Inne';
}

  
// export const genAltText = (fileName) => {
//     // Usuń rozszerzenie pliku, np. ".svg" lub ".webp"
//     const nameWithoutExtension = fileName.split('.').slice(0, -1).join(' ');
//     // Zamień myślniki na spacje, jeśli są
//     const _altText = nameWithoutExtension.replace(/-/g, ' ');
//     // Zwróć opis z dużą literą na początku
//     return _altText.charAt(0).toUpperCase() + _altText.slice(1);
// }
export const genAltText = (filePath) => {
    // Wyodrębnij nazwę pliku z URL lub ścieżki
    const fileName = filePath.substring(filePath.lastIndexOf('/') + 1);

    // Usuń rozszerzenie pliku, np. ".svg" lub ".webp"
    const nameWithoutExtension = fileName.split('.').slice(0, -1).join(' ');

    // Zamień myślniki na spacje, jeśli są
    const _altText = nameWithoutExtension.replace(/-/g, ' ');

    // Zwróć opis z dużą literą na początku
    return _altText.charAt(0).toUpperCase() + _altText.slice(1);
};

export const generateErrorMessage = (fieldKey) => {
    return <FormattedMessage
        id="FieldIsRequired"
        defaultMessage="{field} is required"
        values={{ field: <FormattedMessage id={fieldKey} /> }}
    />;
};

export const getSourceIcon = (source) => {
    const iconMap = {
        // amazon: IconPaths.amazonIcon,
        // elibri: IconPaths.elibriIcon,
        // googlebooks: IconPaths.googleBooksIcon,
        // bn: IconPaths.bnIcon,
        buuki: IconPaths.buukiIcon,
    };
    return iconMap[source] || IconPaths.buukiIcon;
}

export const getSourceName = (source) => {
    const sourceNameMap = {
        amazon: 'Amazon',
        bn: 'Biblioteka Narodowa',
        googlebooks: 'Google Books',
        elibri: 'Elibri'
    };
    return sourceNameMap[source] || process.env.NEXT_PUBLIC_WEBPAGE_DOMAIN_NAME;
}

export const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

// export const hasSubArray = (array, subarray) => {
//     if (array != undefined && subarray != undefined && subarray != null) {
//         // Check if both arrays are empty
//         if (array.length == 0 && subarray.length == 0) {
//             return true;
//         }
//         //Loop through the subarray and check if the element in question is present in the array
//         for (let i = 0; i < subarray.length; i++) {
//             for (let j = 0; j < array.length; j++) {
//                 if ( subarray[i].id === array[j].id ){
//                     return true;
//                 }     
//             }
//         }
//     }
//     return false;
// }
export const hasSubArray = (array, subarray) => {
    if (array && subarray) {
        return subarray.some(subItem => 
            array.some(arrayItem => arrayItem.id === subItem.id)
        );
    }
    return false;
}

export const imageToBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        
        reader.onload = function(event) {
            resolve(event.target.result);
        };
        
        reader.onerror = function(error) {
            reject(new Error("Error reading file: ", error));
        };
        
        reader.readAsDataURL(file);
    });
}

export const isValidFbUrl = urlString => {
    var urlPattern = new RegExp('^(https?:\\/\\/)?'+ // validate protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // validate domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // validate OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // validate port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // validate query string
    '(\\#[-a-z\\d_]*)?$','i'); // validate fragment locator
    // var fbPattern = new RegExp(/^(https?:\/\/){0,1}(www|m\.){0,1}facebook\.com/);
    // var fbPattern = new RegExp(/^(https?:\/\/(www|m)\.facebook\.com\/)([A-Za-z0-9\.]+)(\/)?$/);
    var fbPattern = new RegExp(/^(https?:\/\/(www|m)\.facebook\.com\/)([A-Za-z0-9.]+)(\/)?$/);
    if( urlPattern.test(urlString) )
        if( fbPattern.test(urlString) )
            return true
        else
            return false
    else
      return false
}

export const isValidUrl = urlString => {
    var urlPattern = new RegExp('^(https?:\\/\\/)?'+ // validate protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // validate domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // validate OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // validate port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // validate query string
    '(\\#[-a-z\\d_]*)?$','i'); // validate fragment locator
    // var fbPattern = new RegExp(/^(https?:\/\/){0,1}(www|m\.){0,1}facebook\.com/);
    // var fbPattern = new RegExp(/^(https?:\/\/(www|m)\.facebook\.com\/)([A-Za-z0-9\.]+)(\/)?$/);
    if( urlPattern.test(urlString) )
            return true
    else
      return false
}

// Funkcja do przekształcenia tablicy JSON w obiekt
export const jsonArrayToObject = (jsonArray) => {
    // Sprawdzamy, czy podana data jest tablicą
    if (Array.isArray(jsonArray)) {
        // Tworzymy nowy obiekt
        let result = {};
        // Iterujemy przez tablicę, przypisując każdy element do obiektu z kluczem będącym jego indeksem
        jsonArray.forEach((item, index) => {
            result[index] = item;
        });
        return result;
    } else if (typeof jsonArray === 'object' && jsonArray !== null) {
        // Jeśli data jest już obiektem (i nie jest null), zwracamy ją bez zmian
        return jsonArray;
    } else {
        // W innych przypadkach zwracamy dane wejściowe bez zmian
        return jsonArray;
    }
}

export const removeHTMLTagsAndTruncate = (text, maxLength) => {
    // Usuń tagi HTML ze stringa
    const strippedText = text.replace(/<[^>]+>/g, '');
  
    // Skróć tekst do maksymalnej długości
    let truncatedText = strippedText.substring(0, maxLength);
  
    // Dodaj trzy kropki na końcu, jeśli tekst jest dłuższy niż maksymalna długość
    if (strippedText.length > maxLength) {
      truncatedText += '...';
    }
  
    return truncatedText;
}

export const renderStatusIcon = (value) => {
    if (value == true) {
      return <FaCheck style={{ color: 'green', marginLeft: '10px', marginBottom: '10px' }} />;
    } else if (value == false) {
      return <FaTimes style={{ color: 'red', marginLeft: '10px', marginBottom: '10px' }} />;
    } else {
      return <FaQuestion style={{ color: 'gray', marginLeft: '10px', marginBottom: '10px' }} />;
    }
}
// export const renderStatusLabel = (value) => {
//     switch(value) {
//       case 1: return "Dostępne";
//       case 0: return "Niedostępne";
//       default: return "Nieokreślone";
//     }
// }

export const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
        ref.current = value; //assign the value of ref to the argument
    },[value]); //this code will run when the value of 'value' changes
    return ref.current; //in the end, return the current ref value.
}
  
export const useWindowDimensions = () => {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    // Runs only on the first render
    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    return windowDimensions;
}

export const validateEmailAddress = (email) => {
    if ( email && email.length > 0 ) {
        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }else{
        return false;
    }
}

// Funkcja do walidacji formularza
export const validateForm = (formData, requiredFields, defaultDropdownValues) => {
    // Filtruje i zwraca pola, które są wymagane, ale puste lub mają wartość domyślną
    const missingFields = requiredFields.filter(field => 
        !formData[field] || formData[field] === defaultDropdownValues[field]
    );

    // Dodatkowa walidacja dla adresu e-mail, jeśli pole jest wypełnione
    if (formData.email && !validateEmailAddress(formData.email)) {
        toast.error(
            <FormattedMessage
                id="InvalidEmailAddress"
                defaultMessage="Użyj prawidłowego adresu e-mail!"
            />
        );
        missingFields.push('email');
    }

    // Komponent do wyświetlania listy brakujących pól
    const MissingFieldsMessage = ({ fields }) => (
        <>
            {fields.map((field, index) => {
                // Konwertuje nazwę pola na format z pierwszą wielką literą
                const fieldKey = field.charAt(0).toUpperCase() + field.slice(1);
                return (
                    <React.Fragment key={index}>
                        {/* Wyświetla przetłumaczoną nazwę pola */}
                        <FormattedMessage id={fieldKey} defaultMessage={fieldKey} />
                        {/* Dodaje przecinek po każdym polu, oprócz ostatniego */}
                        {index < fields.length - 1 ? ', ' : ''}
                    </React.Fragment>
                );
            })}
        </>
    );

    if (missingFields.length > 0) {
        // Jeśli brakuje jakichkolwiek pól, wyświetl komunikat błędu
        toast.error(
            <FormattedMessage
                id="FieldsAreRequired"
                defaultMessage="Następujące pola są wymagane: {fields}"
                values={{ fields: <MissingFieldsMessage fields={missingFields} /> }}
            />
        );

        // Zwraca obiekt z informacją, że formularz jest niepoprawny i listą brakujących pól
        return { isValid: false, missingFields };
    }

    // Zwraca obiekt z informacją, że formularz jest poprawny
    return { isValid: true, missingFields: [] };

    // PropTypes
    MissingFieldsMessage.propTypes = {
        fields: PropTypes.array,
    };
};
