// src/contexts/BreadcrumbsContext.js
import React from "react";
import { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';

// Utwórz nowy Context
export const BreadcrumbsContext = createContext({
  breadcrumbs: [],
  setBreadcrumbs: () => {}
});

// Hook do użycia kontekstu
export const useBreadcrumbs = () => useContext(BreadcrumbsContext);

export const BreadcrumbsProvider = ({ children, data = [] }) => {
  const [breadcrumbs, setBreadcrumbs] = useState(data);

  return (
    <BreadcrumbsContext.Provider value={{ breadcrumbs, setBreadcrumbs }}>
      {children}
    </BreadcrumbsContext.Provider>
  );
};

// PropTypes
BreadcrumbsContext.propTypes = {
  children: PropTypes.node,
  data: PropTypes.array,
};

BreadcrumbsProvider.propTypes = {
  children: PropTypes.node,
  data: PropTypes.array,
};
