// src/lib/redux/userOpinionsBooks/ApiReducer.js
import ACTION_TYPES from './ActionTypes';

const initialState = {
  loading: false,
  data: [], // Lista obiektów user opinions
  error: '',
};

const userFavoritesBookOffersReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.OPINION_BOOK_PENDING:
            return {
                ...state,
                loading: true,
            };
        case ACTION_TYPES.OPINION_BOOK_SET:
            return {
                ...state,
                // data: [...state.data, action.payload.details],
                data: [...state.data, action.payload],
                loading: false,
            };
        case ACTION_TYPES.OPINION_BOOK_REMOVE:
            return {
                ...state,
                data: state.data.filter(item => item.id !== action.payload),
                loading: false,
              };
        case ACTION_TYPES.OPINION_BOOK_ERRORR:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        case ACTION_TYPES.OPINION_BOOK_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        default:
            return state;
    }
};

export default userFavoritesBookOffersReducer;
