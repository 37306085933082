// src/contexts/CategoryContext.js
import React from "react";
import { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';

const CategoryContext = createContext();

export const useCategory = () => useContext(CategoryContext);

export const CategoryProvider = ({ children }) => {
  const [kolomnieCategoryIdsPath, setKolomnieCategoryIdsPath] = useState([]);

  // Funkcja do aktualizacji ID, która może być wywoływana w komponencie nawigacji
  const updateKolomnieCategoryIdsPath = (selectedCategoryPath) => {
    setKolomnieCategoryIdsPath(selectedCategoryPath);
  };

  return (
    <CategoryContext.Provider value={{ kolomnieCategoryIdsPath, updateKolomnieCategoryIdsPath }}>
        {children}
    </CategoryContext.Provider>
  );
};

// PropTypes
CategoryProvider.propTypes = {
  children: PropTypes.node,
};
