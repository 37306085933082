// src/pages/_app.js
import 'reflect-metadata';
import React, { useState, useEffect } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import PropTypes from 'prop-types';
import { SessionProvider } from 'next-auth/react';
import { HelmetProvider } from 'react-helmet-async';
import Head from 'next/head';

import '/public/styles/index.css';

import { store, persistor } from '../lib/redux/store';
import { AvailableSoonProvider } from '@/contexts/AvailableSoonContext';
import { LanguageProvider } from '../contexts/LanguageContext';
import { BreadcrumbsProvider } from '../contexts/BreadcrumbsContext';
import { CategoryProvider } from '../contexts/CategoryContext';
import { FavoritesProvider } from '../contexts/FavoritesContext';
import { GeolocationProvider } from '../contexts/GeolocationContext';

// import { reportWebVitals } from '/public/reportWebVitals';

function MyApp({ Component, pageProps }) {
  useEffect(() => {
    if ("serviceWorker" in navigator) {
      window.addEventListener("load", function () {
        navigator.serviceWorker.register("/service-worker.js").then(
          function (registration) {
            console.log("Service Worker registration successful:", registration);
          },
          function (err) {
            console.log("Service Worker registration failed:", err);
          }
        );
      });
    }
  }, []);

  return (
    <SessionProvider session={pageProps.session}>
        <HelmetProvider>
            <LanguageProvider>
              <React.StrictMode>
                <Provider store={store}>
                  <PersistGate loading={null} persistor={persistor}>
                    <AvailableSoonProvider>
                      <FavoritesProvider>
                        <CategoryProvider>
                          <GeolocationProvider>
                            <BreadcrumbsProvider>
                              <Component {...pageProps} />
                            </BreadcrumbsProvider>
                          </GeolocationProvider>
                        </CategoryProvider>
                      </FavoritesProvider>
                    </AvailableSoonProvider>
                  </PersistGate>
                </Provider>
              </React.StrictMode>
            </LanguageProvider>
            {/* Dodaj tagi preload dla stylów CSS tutaj */}
            {/* <link rel="preload" href="/styles/index.css" as="style" /> */}
        </HelmetProvider>
    </SessionProvider>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

// PropTypes
MyApp.propTypes = {
  Component: PropTypes.elementType.isRequired,
  pageProps: PropTypes.object.isRequired,
}

export default MyApp;
