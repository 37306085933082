// src/lib/redux/locationBookOffersCounts/Reducer.js
import { FETCH_LOCATION_BOOK_OFFERS_COUNTS_REQUEST, SET_LOCATION_BOOK_OFFERS_COUNTS } from './Actions';

const initialState = {
    data: [], // Początkowy stan przechowujący dane o liczbie książek w lokalizacjach
    loading: false,
};
  
export default function locationBookOffersCountsReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_LOCATION_BOOK_OFFERS_COUNTS_REQUEST: // Dodaj akcję rozpoczęcia pobierania danych
      return {
        ...state,
        loading: true,
    };
    case SET_LOCATION_BOOK_OFFERS_COUNTS:
      return {
        ...state,
        data: action.payload,
        loading: false, // Zakończ stan "loading" po pobraniu danych
      };
    default:
      return state;
  }
}
  