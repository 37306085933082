// src/contexts/LanguageContext.js
import React from "react";
import { createContext, useContext, useState } from 'react';
import { IntlProvider } from 'react-intl';
import Cookies from 'universal-cookie';
import PropTypes from 'prop-types';

import '@formatjs/intl-relativetimeformat/polyfill';
import '@formatjs/intl-relativetimeformat/locale-data/en';
import '@formatjs/intl-relativetimeformat/locale-data/pl'
import '@formatjs/intl-relativetimeformat/locale-data/de';
import '@formatjs/intl-relativetimeformat/locale-data/uk';

import English from '../lang/en.json';
import Polish from '../lang/pl.json';
import German from '../lang/de.json';
import Ukrainian from '../lang/ua.json';

const LanguageContext = createContext();

export const useLanguage = () => useContext(LanguageContext);

const cookies = new Cookies();

// var local = navigator.language;
var local = cookies.get('selectedLanguage') || 'pl';
let lang = 'Polish';
switch (local) {
    case 'en':
        lang = English;
        break;
    case 'de':
        lang = German;
        break;
    case 'pl':
        lang = Polish;
        break;
    case 'uk':
        lang = Ukrainian;
        break;
    default:
        lang = Polish;
        break;
}

export const LanguageProvider = ({ children }) => {
    const [locale, setLocale] = useState(local);
    const [messages, setMessages] = useState(lang);
    const [userSelectedLanguage, setUserSelectedLanguage] = useState(false);

    const languageMap = {
        en: English,
        de: German,
        pl: Polish,
        uk: Ukrainian
    };

    const selectLanguage = (value, userInitiated = false) => {
        setLocale(value);
        setUserSelectedLanguage(userInitiated); // Ustawiamy na true, gdy zmiana języka jest inicjowana przez użytkownika
        setMessages(languageMap[value]);
        cookies.set('selectedLanguage', value, { path: '/', sameSite: 'none', secure: true });
    }

    return (
        <LanguageContext.Provider value = {{locale, selectLanguage, userSelectedLanguage}}>
            <IntlProvider messages={messages} locale={locale}>
                {children}
            </IntlProvider>
        </LanguageContext.Provider>
    );
};

// PropTypes
LanguageProvider.propTypes = {
    children: PropTypes.node,
};