// src/lib/redux/recentlyViewedBookOffers/Actions.js

// Definiowanie typu akcji
export const RECENTLY_VIEWED_BOOK_OFFERS_SUCCESS = 'RECENTLY_VIEWED_BOOK_OFFERS';
export const RECENTLY_VIEWED_BOOK_OFFERS_PENDING = 'RECENTLY_VIEWED_BOOK_OFFERS_PENDING';
export const RECENTLY_VIEWED_BOOK_OFFERS_ERROR = 'RECENTLY_VIEWED_BOOK_OFFERS_ERROR';

// Twórca akcji
export const addOrUpdateRecentlyViewdBookOffers = (data) => {
    return {
        type: RECENTLY_VIEWED_BOOK_OFFERS_SUCCESS,
        payload: data,
    };
};

// Twórca akcji dla rozpoczęcia procesu (np. ładowania danych)
export const setRecentlyViewedBookOffersPending = () => {
    return {
        type: RECENTLY_VIEWED_BOOK_OFFERS_PENDING
    };
};

// Twórca akcji dla obsługi błędów
export const setRecentlyViewedBookOffersError = (error) => {
    return {
        type: RECENTLY_VIEWED_BOOK_OFFERS_ERROR,
        payload: error
    };
};
