import { createStore, combineReducers, applyMiddleware } from 'redux';
// import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';

import {
    persistStore,
    persistReducer,
} from 'redux-persist';
import { composeWithDevTools } from 'redux-devtools-extension';
// import { createLogger } from 'redux-logger';

// Imports: Redux
import thunk from 'redux-thunk';

import bookInformationReducer from './bookInformation/ApiReducer';
import bookOffersReducer from './bookOffers/ApiReducer';
import bookOffersByIdReducer from './bookOffersById/ApiReducer';
import bookOffersSliceReducer from './bookOffersSlice';
import buukiCategoriesReducer from './buukiCategories/ApiReducer';
import locationBookOffersCountsReducer from './locationBookOffersCounts/Reducer';
import locationsSliceReducer from './locationsSlice';
import recentlyViewedBookOffersReducer from './recentlyViewedBookOffers/Reducer';
import sessionReducer from './sessionReducer/Reducer';
import similarBookOffersReducer from './similarBookOffers/ApiReducer';
import userBookOffersReducer from './userBookOffers/ApiReducer';
import userFavoritesBookOffersReducer from './userFavoritesBookOffers/ApiReducer';
import userLocationsReducer from './userLocations/ApiReducer';
import userOpinionsBooksReducer from './userOpinionsBooks/ApiReducer';

// Middleware: Redux Persist Config
const persistConfig = {
  key: 'root',
  storage
};

// combine all reducers
const appReducers = combineReducers({
  bookInformation: bookInformationReducer,
  bookOffers: bookOffersReducer,
  bookOffersById: bookOffersByIdReducer,
  bookOffersSlice: bookOffersSliceReducer,
  buukiCategories: buukiCategoriesReducer,
  locationBookOffersCounts: locationBookOffersCountsReducer,
  locationsSlice: locationsSliceReducer,
  recentlyViewedBookOffers: recentlyViewedBookOffersReducer,
  session: sessionReducer,
  similarBookOffers: similarBookOffersReducer,
  userBookOffers: userBookOffersReducer,
  userFavoritesBookOffers: userFavoritesBookOffersReducer,
  userLocations: userLocationsReducer,
  userOpinionsBooks: userOpinionsBooksReducer,
});

// Middleware: Redux Persist Persisted Reducer
const persistedReducer = persistReducer(persistConfig, appReducers);

// const logger = createLogger();

// Redux: Store
const store = createStore(
  persistedReducer,
  // composeWithDevTools(applyMiddleware(thunk, logger)),
  composeWithDevTools(applyMiddleware(thunk)),
);

// Middleware: Redux Persist Persister
let persistor = persistStore(store);

// Exports
export { store, persistor };
