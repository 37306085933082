// src/lib/redux/recentlyViewedBookOffers/Reducer.js
import { RECENTLY_VIEWED_BOOK_OFFERS_PENDING } from './Actions';
import { RECENTLY_VIEWED_BOOK_OFFERS_SUCCESS } from './Actions';
import { RECENTLY_VIEWED_BOOK_OFFERS_ERROR } from './Actions';

const initialState = {
    loading: false,
    data: [],
    error: '',
};

const recentlyViewedBookOffersReducer = (state = initialState, action) => {
    switch (action.type) {
        case RECENTLY_VIEWED_BOOK_OFFERS_PENDING:
            return {
                ...state,
                loading: true,
            };
        case RECENTLY_VIEWED_BOOK_OFFERS_SUCCESS:
            // const updatedRecentlyViewedBookOffers = state.data.filter(id => id !== action.payload.id);
            const updatedRecentlyViewedBookOffers = state.data.filter(item => item.id !== action.payload.id);
            return {
                ...state,
                data: [ action.payload, ...updatedRecentlyViewedBookOffers],
            };
        case RECENTLY_VIEWED_BOOK_OFFERS_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        default:
            return state;
    }
};

export default recentlyViewedBookOffersReducer;

