// src/lib/redux/bookOffersSlice/index.js
import { createSlice } from '@reduxjs/toolkit';

export const bookOffersSlice = createSlice({
    name: 'bookOffers',
    initialState: {
        bookOffersId: null,
    },
    reducers: {
        setBookOffersId: (state, action) => {
            state.bookOffersId = action.payload;
        },
        resetBookOffersId: (state) => {
            state.bookOffersId = null;
        },
    },
});

export const { setBookOffersId, resetBookOffersId } = bookOffersSlice.actions;

export default bookOffersSlice.reducer;