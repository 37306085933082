// src/lib/redux/userLocations/ApiReducer.js
import ACTION_TYPES from './ActionTypes';

const initialState = {
  loading: false,
  data: [],
  pagination: {},
  error: '',
};

const userLocationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.USER_LOCATIONS_PENDING:
      return {
        ...state,
        loading: true,
      };
    case ACTION_TYPES.USER_LOCATIONS_SUCCESS:
      return {
        ...state,
        // data: action.payload.items,
        data: action.payload,
        pagination: action.payload.pagination, // Dodanie informacji o paginacji
        loading: false,
      };
    case ACTION_TYPES.USER_LOCATIONS_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    default:
      return state;
  }
};

export default userLocationsReducer;