// src/lib/redux/sessionReducer/Reducer.js
import { SESSION_UPDATE, SESSION_CLEAR, SESSION_UPDATE_PICTURE } from './Actions';

const initialSessionState = {
    user: null, // Przechowuj dane użytkownika
    // Możesz dodać więcej pól zależnie od potrzeb
};

const sessionReducer = (state = initialSessionState, action) => {
    switch (action.type) {
        case SESSION_UPDATE:
            return {
                ...state,
                user: action.payload,
            };
        case SESSION_CLEAR:
            return initialSessionState;
        case SESSION_UPDATE_PICTURE:
            return {
                ...state,
                user: {
                    ...state.user,
                    picture: action.payload,
                },
            };
        default:
            return state;
    }
};

export default sessionReducer;
