// src/lib/redux/sessionReducer/Actions.js
// Dodatkowe typy akcji
export const SESSION_UPDATE = 'SESSION_UPDATE';
export const SESSION_CLEAR = 'SESSION_CLEAR';
export const SESSION_UPDATE_PICTURE = 'SESSION_UPDATE_PICTURE';

// Twórca akcji dla aktualizacji sesji
export const updateSession = (sessionData) => {
    return {
        type: SESSION_UPDATE,
        payload: sessionData,
    };
};

// Twórca akcji dla czyszczenia sesji
export const clearSession = () => {
    return {
        type: SESSION_CLEAR,
    };
};

export const updateUserPicture = (picture) => {
    return {
        type: SESSION_UPDATE_PICTURE,
        payload: picture,
    };
};
