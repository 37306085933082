// src/lib/redux/bookOffersById/ApiReducer.js
import ACTION_TYPES from './ActionTypes';

const initialState = {
  loading: false,
  data: [],
  pagination: {},
  error: '',
};

const bookOffersByIdReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.BOOK_OFFERS_BY_ID_PENDING:
      return {
        ...state,
        loading: true,
      };
    case ACTION_TYPES.BOOK_OFFERS_BY_ID_SUCCESS:
      return {
        ...state,
        data: action.payload.items, // Aktualizacja danych
        pagination: action.payload.pagination, // Dodanie informacji o paginacji
        loading: false,
      };
    case ACTION_TYPES.BOOK_OFFERS_BY_ID_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    default:
      return state;
  }
};

export default bookOffersByIdReducer;