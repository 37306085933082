// src/contexts/FavoritesContext.js
import React, { useState, createContext, useContext, useEffect } from 'react';
import { useSession } from 'next-auth/react';
import { signIn } from 'next-auth/react';
import PropTypes from 'prop-types';

// Tworzenie kontekstu
const FavoritesContext = createContext({
    toggleFavorite: () => {},
    isFavorite: () => false,
});

export const useFavorite = () => useContext(FavoritesContext);

export const FavoritesProvider = ({ children }) => {
    const [favorites, setFavorites] = useState(new Set());
    const { data: session } = useSession(); // Pobranie sesji
    const [, forceUpdate] = useState();
    // console.log("FavoritesContext => favorites =>", favorites)

    const addFavoriteToBackend = async (book_offers_id) => {
        if (!session) return; // Sprawdzenie, czy użytkownik jest zalogowany
        try {
            await fetch('/api/user-favorites', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ 
                    book_offers_id, 
                    users_id: session.user.id 
                }),
            });
        } catch (error) {
            console.error('Błąd podczas dodawania do ulubionych: ', error);
        }
    };
    
    const removeFavoriteFromBackend = async (book_offers_id) => {
        if (!session) return; // Sprawdzenie, czy użytkownik jest zalogowany
        try {
            await fetch(`/api/user-favorites/${book_offers_id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    // 'Authorization': `Bearer ${session.accessToken}`, // Przykład przekazania tokena sesji
                },
                body: JSON.stringify({
                    users_id: session.user.id
                })
            });
        } catch (error) {
            console.error('Błąd podczas usuwania z ulubionych: ', error);
        }
    };

    // Użyj useEffect, aby załadować ulubione przy montowaniu
    useEffect(() => {
        // Funkcja do ładowania początkowych ulubionych
        const loadInitialFavorites = async () => {
            if (!session || !session.user?.id) return; // Sprawdzenie, czy użytkownik jest zalogowany
            try {
                const response = await fetch(`/api/user-favorites?users_id=${session.user.id}`); // Przykład zapytania GET z users_id
                const data = await response.json();

                // // Tworzenie kopii tablicy i odwrócenie jej kolejności
                // const reversedData = [...data].reverse(); // Zakładam, że 'data' to tablica
                // setFavorites(new Set(reversedData)); 
                
                setFavorites(new Set(data)); // setFavorites(new Set(data.favorites));
            } catch (error) {
                console.error('Błąd podczas ładowania ulubionych: ', error);
            }
        };
        loadInitialFavorites();
    }, [session]);

    const toggleFavorite = (bookOffersId) => {
        // Sprawdzenie, czy użytkownik jest zalogowany
        if (!session) {
            // Zapisz aktualny URL do localStorage
            localStorage.setItem('preLoginRoute', window.location.pathname);
            localStorage.setItem('shouldRedirect', 'true');
            localStorage.setItem('postLoginAction', isFavorite(bookOffersId) ? 'remove' : 'add');
            localStorage.setItem('actionId', bookOffersId);

            console.log("toggleFavorite pathname ", localStorage.getItem('preLoginRoute'))
            console.log("toggleFavorite isFavorite(bookOffersId) ", isFavorite(bookOffersId) ? 'remove' : 'add')
            console.log("toggleFavorite book_offers_id ", bookOffersId)
            
            // Przekieruj na stronę logowania
            signIn();
            return; // Zakończ funkcję tutaj
        }
        setFavorites(prevFavorites => {
            let newFavorites = new Set(prevFavorites);
        
            if (newFavorites.has(bookOffersId)) {
                newFavorites.delete(bookOffersId);
                removeFavoriteFromBackend(bookOffersId); // Usuń z backendu
            } else {
                newFavorites.add(bookOffersId);
                addFavoriteToBackend(bookOffersId); // Dodaj do backendu
            }
        
            return newFavorites;
        });
        forceUpdate(n => !n); // Wymuszenie aktualizacji
    };

    const isFavorite = (bookOffersId) => favorites.has(bookOffersId);

    return (
        <FavoritesContext.Provider value={{ favorites: Array.from(favorites), toggleFavorite, isFavorite }}>
            {children}
        </FavoritesContext.Provider>
    );
};

// PropTypes
FavoritesProvider.propTypes = {
    children: PropTypes.node,
};
