// src/contexts/AvailableSoonContext.js
import React, { createContext, useState, useContext, useCallback } from 'react';
import Modal from 'react-bootstrap/Modal';
import CloseButton from 'react-bootstrap/CloseButton';
import Link from 'next/link';
import { FormattedMessage } from 'react-intl';
import Image from 'next/image';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';

import styles from '@/contexts/css/available-soon.module.css';
import { ImagePaths } from '@/constants';
import { genAltText } from '@/utils';

const AvailableSoonContext = createContext();

export const useAvailableSoon = () => useContext(AvailableSoonContext);

const AvailableSoonModal = ({ showModal, setShowModal }) => {
    const router = useRouter();

    const handleBackToMainPage = useCallback(() => {
        setShowModal(false);
        router.push("/");
    }, [router, setShowModal]);

    const handleClose = () => {
        setShowModal(false);
    };
    
    return (
        <Modal show={showModal} onHide={handleClose} className={styles["as--sec"]}>
            {/* <Modal.Header closeButton className={styles["as--hdr"]}>
                <Modal.Title></Modal.Title>
            </Modal.Header> */}
            <Modal.Body className={styles["as--wrp"]}>
                <CloseButton onClick={()=> handleClose()} className={styles["as--c-b"]} />
                <Image src={ImagePaths.availableSoonImg} alt={genAltText(ImagePaths.availableSoonImg)} className={styles["as--i"]} width={"350"} height={350} />
                <div className={styles["as--t"]}>
                    <FormattedMessage
                        id = "AvailableSoon"
                        defaultMessage="Ta część serwisu będzie dostępna wkrótce."
                    />
                </div>
                <div className={styles["as--s"]}>
                    <FormattedMessage
                        id = "AvailableSoonDescription"
                        defaultMessage="Odwiedź nas niebawem… by skorzystać z tej funkcjonalności."
                    />
                </div>
                <Link href="/" className={styles["as--btn"]} onClick={()=> handleBackToMainPage()} >
                    <FormattedMessage
                        id = "BackToMainPage"
                        defaultMessage="Wróć na główną stronę"
                    />
                </Link>
            </Modal.Body>
            {/* <Modal.Footer className={styles["as--footer"]}>
                <Button variant="primary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="secondary" onClick={handleClose}>
                    Save Changes
                </Button>
            </Modal.Footer> */}
        </Modal>
    );
};

export const AvailableSoonProvider = ({ children }) => {
    const [showModal, setShowModal] = useState(false);

    const changeIsShownState = (state) => {
        setShowModal(state);
    };

    return (
        <AvailableSoonContext.Provider value={{ showModal, changeIsShownState }}>
            {showModal && <AvailableSoonModal showModal={showModal} setShowModal={changeIsShownState} />}
            {children}
        </AvailableSoonContext.Provider>
    );
};

// PropTypes
AvailableSoonContext.propTypes = {
    showModal: PropTypes.bool.isRequired,
    setShowModal: PropTypes.func.isRequired,
    children: PropTypes.node,
};
AvailableSoonModal.propTypes = {
    showModal: PropTypes.bool.isRequired,
    setShowModal: PropTypes.func.isRequired,
};

AvailableSoonProvider.propTypes = {
    children: PropTypes.node,
};
