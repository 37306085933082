// src/lib/redux/userRecentlyViewed/ActionTypes.js
const ACTION_TYPES = {
    OPINION_BOOK_PENDING: 'OPINION_BOOK_PENDING',
    OPINION_BOOK_SET: 'OPINION_BOOK_SET',
    OPINION_BOOK_REMOVE: 'OPINION_BOOK_REMOVE',
    OPINION_BOOK_ERRORR: 'OPINION_BOOK_ERRORR',
    OPINION_BOOK_SUCCESS: 'OPINION_BOOK_SUCCESS',
};
export default ACTION_TYPES;

// https://reactnativeforyou.com/react-native-api-call-example-with-axios-redux-and-hooks/
