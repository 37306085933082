// src/lib/redux/locationBookOffersCounts/Actions.js
// Dodatkowe typy akcji
export const FETCH_LOCATION_BOOK_OFFERS_COUNTS_REQUEST = 'FETCH_LOCATION_BOOK_OFFERS_COUNTS_REQUEST';
export const SET_LOCATION_BOOK_OFFERS_COUNTS = 'SET_LOCATION_BOOK_OFFERS_COUNTS';

export const fetchLocationBookOffersCountsRequest = () => ({
    type: FETCH_LOCATION_BOOK_OFFERS_COUNTS_REQUEST,
});

export const setLocationBookOffersCounts = (counts) => ({
    type: SET_LOCATION_BOOK_OFFERS_COUNTS,
    payload: counts,
});
